import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Input, Select, Button, Form, message } from "antd";

const CreateUserPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("manager");
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/createUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email, role, name }),
        }
      );
      const result = await response.json();
      message.success(result.message);
    } catch (error) {
      console.error("Error creating user:", error);
      message.error("Error creating user");
    }
  };

  return (
    <div>
      <h1>Create User</h1>
      <Form onFinish={handleSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item label="Role" name="role" rules={[{ required: true }]}>
          <Select value={role} onChange={(value) => setRole(value)}>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="manager">Manager</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create User
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateUserPage;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Spin, Alert } from "antd";

const UpworkCallbackPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get("code");

      if (authorizationCode) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/upwork/callback`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ code: authorizationCode }),
            }
          );

          if (response.ok) {
            navigate("/manager");
          } else {
            setError(
              `Error posting authorization code: ${response.statusText}`
            );
          }
        } catch (error) {
          setError(`Error handling callback: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        setError("Authorization code not found.");
        setLoading(false);
      }
    };

    handleCallback();
  }, [getAccessTokenSilently, navigate]);

  if (loading) {
    return <Spin tip="Connecting Upwork..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return null;
};

export default UpworkCallbackPage;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card } from "antd";

const ManagerPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [upworkData, setUpworkData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/manager/info`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = await response.json();
        setData(result);

        if (result && result.manager && result.manager.upworkConnected) {
          const upworkResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/manager/upworkAccount`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const upworkResult = await upworkResponse.json();
          setUpworkData(upworkResult);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Unknown error");
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const handleConnectUpWork = () => {
    const clientId = process.env.REACT_APP_UPWORK_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_UPWORK_REDIRECT_URI;
    const responseType = "code";

    const authUrl = `https://www.upwork.com/ab/account-security/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}`;
    window.location.href = authUrl;
  };

  if (error) {
    return (
      <div>
        <h1>{error}</h1>
      </div>
    );
  }

  return (
    <div>
      <h1>Manager Page</h1>
      <Button type="primary" onClick={handleConnectUpWork}>
        Connect UpWork
      </Button>
      <div>
        {/* {data ? (
          <Card title="Manager Info">
            <p>Okta ID: {data.manager.oktaId}</p>
            <p>
              Upwork Connected: {data.manager.upworkConnected ? "Yes" : "No"}
            </p>
            <p>
              Created Date:{" "}
              {new Date(data.manager.createdDateTime).toLocaleString()}
            </p>
            <p>
              Modified Date:{" "}
              {new Date(data.manager.modifiedDateTime).toLocaleString()}
            </p>
          </Card>
        ) : (
          <Spin tip="Loading..." />
        )} */}
      </div>
      {upworkData && (
        <Card title="Upwork Account Info">
          <p>Message: {upworkData.message}</p>
          <h3>User Info</h3>
          <p>ID: {upworkData.user._id}</p>
          <p>Email: {upworkData.user.email}</p>
          <p>Role: {upworkData.user.role}</p>
          <p>Name: {upworkData.user.name}</p>
          <p>Status: {upworkData.user.status}</p>
          <p>
            Created Date:{" "}
            {new Date(upworkData.user.createdDateTime).toLocaleString()}
          </p>
          <p>
            Modified Date:{" "}
            {new Date(upworkData.user.modifiedDateTime).toLocaleString()}
          </p>
          <h3>Organization Info</h3>
          <p>
            Company Name:{" "}
            {upworkData.organizationInfo.data.organization.company.companyName}
          </p>
          <p>
            Organization Name:{" "}
            {upworkData.organizationInfo.data.organization.name}
          </p>
          <img
            src={upworkData.organizationInfo.data.organization.photoUrl}
            alt="Organization"
          />
        </Card>
      )}
    </div>
  );
};

export default ManagerPage;

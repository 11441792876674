import React from "react";
import { Layout, Typography, Image } from "antd";
import LoginButton from "../components/LoginButton";

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const Landing = () => {
  return (
    <Layout>
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Title level={1} style={{fontWeight: "bold" }}>UpFellow</Title>
        <img
          src={process.env.PUBLIC_URL + "/upfellow-logo.png"}
          alt="UpFellow Logo"
          width={100}
          style={{ marginTop: "20px" }}
        />
        <Paragraph
          style={{
            textAlign: "center",
            maxWidth: "600px",
            marginTop: "20px",
            fontSize: "16px",
            lineHeight: "1.5",
            color: "#333"
          }}
        >
          This platform is dedicated to helping you with your agency's Upwork activities. 
          <br/>
          To start using this tool you have to register.
        </Paragraph>
      </Content>
    </Layout>
  );
};

export default Landing;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Spin } from "antd";
import Header from "./components/Header";
import AdminPage from "./pages/AdminPage";
import ManagerPage from "./pages/ManagerPage";
import Landing from "./pages/LandingPage";
import CreateUserPage from "./pages/CreateUserPage";
import UpworkCallbackPage from "./pages/UpworkCallbackPage";
import JobClientNamePage from "./pages/JobClientNamePage";
import NotActivatedPage from "./pages/NotActivatedPage";
import Footer from "./components/Footer";
const { Content } = Layout;

const App = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [role, setRole] = useState(null);

  const rolesKey = "https://localhost/roles";

  useEffect(() => {
    if (user) {
      const getRole = () => {
        if (user && user[rolesKey]) {
          return user[rolesKey][0];
        }
        return null;
      };
      setRole(getRole());
    }
  }, [user]);

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  return (
    <Router>
      <Layout style={{ height: "100vh" }}>
        <Header />
        <Content>
          {!isAuthenticated && <Landing />}
          {isAuthenticated && (
            <Routes>
              <Route
                path="/admin"
                element={
                  role === "admin" ? <AdminPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/manager"
                element={
                  role === "manager" ? <ManagerPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      role === "admin"
                        ? "/admin"
                        : role === "manager"
                        ? "/manager"
                        : role === "member"
                        ? "/jobClientName"
                        : "/notActivated"
                    }
                  />
                }
              />
              <Route
                path="/create-user"
                element={
                  role === "admin" ? <CreateUserPage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/upwork_callback"
                element={<UpworkCallbackPage />}
              />
              <Route
                path="/jobClientName"
                element={
                  role === "member" ? <JobClientNamePage /> : <Navigate to="/" />
                }
              />
              <Route
                path="/notActivated"
                element={
                  role ? <Navigate to="/" /> : <NotActivatedPage />
                }
              />
            </Routes>
          )}
        </Content>
        <Footer />
      </Layout>
    </Router>
  );
};

export default App;
